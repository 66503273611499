import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout, LinkButton, PlanHero, Link,
} from '@components';
import { Arrow } from '@styles/icons';
import { IntroWrapper } from '@styles/rich-text';
import { getKeyValue, compileRichText } from '@utils';
import {
  SMALL, LARGE, XLARGE, LIGHT,
} from '@utils/vars';
import {
  Download, ContentWrapper, TocWrapper, TocItems, TocItem,
} from './plan.styles';

const PlanTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings, policiesLanding,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    content, title, relatedKeyIssueAreas, planDocument, planDocumentDownloadText,
    relatedIssuesTitle, heroImage, intro, planChapters, planChaptersTableOfContentsLabel,
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      navTheme={LIGHT}
      fullWidth
      doNotIndex={doNotIndex}
    >
      <PlanHero
        title={title}
        relatedContent={relatedKeyIssueAreas}
        policiesLanding={policiesLanding}
        relatedContentTitle={relatedIssuesTitle}
        heroImage={heroImage}
        document={planDocument}
        documentDownloadText={planDocumentDownloadText}
      />
      <ContentWrapper>
        <IntroWrapper>
          {intro && (
            compileRichText({ textTheme: 'primary', size: LARGE, locale })(intro)
          )}
          {planChapters && (
          <TocWrapper>
            <h2>{planChaptersTableOfContentsLabel || 'Table of Contents'}</h2>
            <TocItems>
              {planChapters.map((chapter) => {
                const { title: chapterTitle, page: chapterPage } = chapter;
                if (chapterPage && chapterPage[0] && chapterPage[0].pageUrl && chapterTitle) {
                  return (
                    <TocItem key={chapter.id}>
                      <Link href={chapterPage[0].pageUrl}>
                        <span>{chapterTitle}</span>
                        <Arrow strokeColor="primary" height="18px" width="20px" />
                      </Link>
                    </TocItem>
                  );
                }
                return null;
              })}
            </TocItems>
          </TocWrapper>
          )}
        </IntroWrapper>
        {content && (
          compileRichText({ textTheme: 'primary', size: SMALL, locale })(content)
        )}
        {planDocument && planDocumentDownloadText && planDocument.file && planDocument.file.url && (
          <Download>
            <LinkButton
              href={planDocument.file.url}
              size={XLARGE}
              textTheme="white"
            >
              {planDocumentDownloadText}
            </LinkButton>
          </Download>
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default PlanTemplate;

export const planPageQuery = graphql`
  query PlanBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulPlan {
          id
          title
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 940) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          intro {
            raw
            references {
              pageUrl
              contentful_id
            }
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
              ... on ContentfulSocialEmbed {
                caption {
                  raw
                }
                type
                contentful_id
                contentfulid
                internal {
                  type
                }
                videoThumbnail {
                  fluid(quality: 90, maxWidth: 768) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
          planDocumentDownloadText
          planDocument {
            file {
              url
            }
          }
          planChaptersTableOfContentsLabel
          planChapters {
            title
            id
            page {
              pageUrl
            }
          }
          relatedIssuesTitle
          relatedKeyIssueAreas {
            page {
              pageUrl
            }
            title
            id
          }
        }
      }
    }
    policiesLanding: contentfulPlansLanding(dummyContent: {ne: true}, page: {elemMatch: {language: {eq: $locale}}}) {
      page {
        pageUrl
      }
      title
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
